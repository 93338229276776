import React, { forwardRef } from 'react'

import { DiscardPointerMoveForMobileWrapper } from '../DiscardPointerMoveForMobileWrapper'

import { StyledCaretDown, StyledTrigger } from './NavTrigger.styled'
import { NavTriggerProps } from './types'

export const NavTrigger = forwardRef<HTMLButtonElement, NavTriggerProps>(({ children, ...props }, forwardedRef) => (
  <DiscardPointerMoveForMobileWrapper>
    <StyledTrigger {...props} ref={forwardedRef}>
      {props.href && <a href={props.href}>{children}</a>}
      {!props.href && (
        <>
          {children}
          <StyledCaretDown aria-hidden />
        </>
      )}
    </StyledTrigger>
  </DiscardPointerMoveForMobileWrapper>
))
