import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Content, Item } from '@radix-ui/react-navigation-menu'

import { pxToRem } from '../../../utils/pixelToRem'

import { StyledContentProps } from './types'

export const StyledItem = styled(Item)<{
  order?: number
}>`
  ${(props) => (props.order ? `order ${props.order};` : '')}

  > div {
    display: flex;
  }
`

export const StyledContent = styled(Content)<StyledContentProps>`
  position: absolute;
  width: 100%;
  padding: auto ${(props) => pxToRem(16, props.theme.baseElemSize)};

  ${up('md')} {
    width: unset;
    position: relative;
    top: 0;
    left: 0;
    width: ${(props) => props.desktopwidth || pxToRem(440, props.theme.baseElemSize)};
    z-index: 1000;
  }
`
