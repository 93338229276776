import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Trigger } from '@radix-ui/react-navigation-menu'

import { CaretDown } from '../../../icons'
import { pxToRem } from '../../../utils/pixelToRem'

const SUBMENU_ITEMS_ANIMATION_TIME = '200ms'

export const StyledTrigger = styled(Trigger)`
  all: unset;
  user-select: none;
  padding: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  width: 100%;
  position: inherit;
  color: black;
  border: none;
  background-color: ${(props) => props.theme.nav.mobileBackground};
  display: flex;
  font-size: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  font-weight: 700;
  line-height: ${(props) => pxToRem(22, props.theme.baseElemSize)};
  cursor: pointer;
  > span {
    text-align: left;
    flex: 1;
  }
  > a {
    text-decoration: none;
    color: unset;
    &:visited {
      color: inherit;
    }
  }

  ${up('md')} {
    display: inherit;
    font-weight: 600;
    line-height: 1;
    background-color: transparent;
    color: ${(props) => props.theme.nav.text};
    outline: none;
    border-radius: ${(props) => pxToRem(4, props.theme.baseElemSize)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${(props) => pxToRem(7, props.theme.baseElemSize)};
    outline: none;
    cursor: pointer;
    padding: ${(props) => pxToRem('6 22', props.theme.baseElemSize)};
  }
`

export const StyledCaretDown = styled(CaretDown)`
  display: block;
  float: right;
  fill: black;
  width: ${(props) => pxToRem(10, props.theme.baseElemSize)};
  transform: rotate(-90deg);

  ${up('md')} {
    position: relative;
    transform: unset;
    top: ${(props) => pxToRem(1, props.theme.baseElemSize)};
    width: ${(props) => pxToRem(10, props.theme.baseElemSize)};
    fill: ${(props) => props.theme.nav.text};
    [data-state='open'] & {
      transform: rotate(-180deg);
    }
    @media (prefers-reduced-motion: no-preference) {
      transition: transform ${SUBMENU_ITEMS_ANIMATION_TIME} ease;
    }
  }
`
