// testing the styled-breakpoints (this comment should be removed later)
import { createTheme } from 'styled-breakpoints'

import { breakpoints } from '../constants'

export const defaultThemeBreakpoints = createTheme({
  sm: `${breakpoints.sm}px`,
  md: `${breakpoints.md}px`,
  lg: `${breakpoints.lg}px`,
})
