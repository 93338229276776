import React from 'react'

import { Award, BookAlt, Calculator, CashOffer, LandMark, Percentage, Search, TradeIn } from '../../../icons'

import { IconResolverProps } from './types'

const navMenuLinkIcons = {
  award: Award,
  bookAlt: BookAlt,
  calculator: Calculator,
  cashOffer: CashOffer,
  landMark: LandMark,
  search: Search,
  tradeIn: TradeIn,
  percentage: Percentage,
}

export const IconResolver = ({ icon, ...props }: IconResolverProps) => {
  if (!icon) return null

  const IconComponent = navMenuLinkIcons[icon]
  if (!IconComponent) return null

  return <IconComponent {...props} />
}
