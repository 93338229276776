import React from 'react'

import { UserAvatar } from '../UserAvatar'

import { UserHeadlineProps } from './types'
import {
  StyledUserDetailsContainer,
  StyledUserEmail,
  StyledUserHeadlineContainer,
  StyledUserName,
} from './UserHeadline.styled'

export const UserHeadline = ({ name, email }: UserHeadlineProps) => (
  <StyledUserHeadlineContainer>
    <UserAvatar name={name} />
    <StyledUserDetailsContainer>
      <StyledUserName>{name}</StyledUserName>
      <StyledUserEmail>{email}</StyledUserEmail>
    </StyledUserDetailsContainer>
  </StyledUserHeadlineContainer>
)
