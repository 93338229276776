const calcPxToRem = (value: number, baseSize: number) => `${parseFloat((value / baseSize).toFixed(3))}rem`

export function pxToRem(value: number | string, baseSize = 16): string {
  if (typeof value === 'number') {
    return calcPxToRem(value, baseSize)
  }
  const values = value.split(' ')
  const retArray: string[] = []
  values.forEach((val) => retArray.push(calcPxToRem(parseInt(val, 10), baseSize)))
  return retArray.join(' ')
}

export const getRootElemFontSize = () => {
  let rootFontSize = 16
  const rootElem = document.querySelector('html')
  if (rootElem) {
    rootFontSize = parseFloat(getComputedStyle(rootElem).getPropertyValue('font-size'))
  }
  return rootFontSize
}
