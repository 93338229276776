import { useMediaQuery } from 'react-responsive'

import { breakpoints } from '../../constants'

import { ResponsiveProps } from './types'

export const useIsDesktop = () => useMediaQuery({ minWidth: breakpoints.lg })
export const useIsLaptop = () => useMediaQuery({ minWidth: breakpoints.md, maxWidth: breakpoints.lg - 1 })
export const useIsTablet = () => useMediaQuery({ minWidth: breakpoints.sm, maxWidth: breakpoints.md - 1 })
export const useIsMobile = () => useMediaQuery({ maxWidth: breakpoints.sm - 1 })

export const Desktop = ({ children }: ResponsiveProps) => {
  return useIsDesktop() ? children : null
}

export const Laptop = ({ children }: ResponsiveProps) => {
  return useIsLaptop() ? children : null
}

export const Tablet = ({ children }: ResponsiveProps) => {
  return useIsTablet() ? children : null
}

export const Mobile = ({ children }: ResponsiveProps) => {
  return useIsMobile() ? children : null
}
