import React from 'react'

import { AngleLeftLight } from '../../../icons'

import {
  NavMenuBackButtonAndTitleProps,
  NavMenuChildrenContainerProps,
  NavMenuCloseButtonProps,
  NavMenuGroupProps,
  NavMenuHamburgerButtonProps,
} from './types'

export const NavMenuGroup = ({ className, children, state }: NavMenuGroupProps) => (
  <div id="menu-group" className={className} data-state={state}>
    {children}
  </div>
)

export const NavMenuChildrenContainer = ({ className, children }: NavMenuChildrenContainerProps) => (
  <div className={className}>{children}</div>
)

export const NavMenuHamburgerButton = ({ className, onClick }: NavMenuHamburgerButtonProps) => (
  <button type="button" className={className} onClick={() => onClick()}>
    <span />
    <span />
    <span />
  </button>
)

export const NavMenuCloseButton = ({ className, onClick }: NavMenuCloseButtonProps) => (
  <button type="button" className={className} onClick={() => onClick()}>
    <span />
    <span />
  </button>
)

export const NavMenuBackButtonAndTitle = ({ className, title, onClick }: NavMenuBackButtonAndTitleProps) => (
  <div className={className}>
    <button type="button" onClick={() => onClick()}>
      <AngleLeftLight />
    </button>
    <div>{title}</div>
  </div>
)
