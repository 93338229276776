export const ELECTRIC_BLUE = '#46b6ff'
export const ELECTRIC_BLUE_HOVER = '#2CA4F2'
export const MIST_BLUE = '#F2F9FE'
export const WHITE = 'white'
export const COOL_GRAY_2 = '#72757D'
export const COOL_GRAY_3 = '#8E929C'
export const COOL_GRAY_5 = '#DBDFE6'
export const BLUE_BG_HOVER = '#e9f6ff'
export const DARK_BLUE = '#273653'
export const AA_BLUE = '#1192E5'
export const BLUE_2 = '#E9F6FF'
