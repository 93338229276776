import React from 'react'

import { IconProps } from '../types'

export const HomeLightLogoTransparent = ({ className, width = 24, height = 24, fill = '#fff' }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 0 24 24">
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="m1.03711 16.8787c1.86994 4.195 6.07354 7.1209 10.96289 7.1209 4.8872 0 9.0891-2.9233 10.9604-7.1153l-10.9536-7.97414zm11.48099.0079h2.317v-2.6652h-2.317zm0 3.6951h2.317v-2.6652h-2.317zm-3.34684 0h2.31724v-2.6652h-2.31724zm0-3.6951h2.31724v-2.6652h-2.31724z" />
      <path d="m23.7317 14.5248c.1745-.8143.2683-1.6584.2683-2.5248 0-6.62743-5.3724-12-12-12-6.62743 0-12 5.37257-12 12 0 .8642.0934286 1.7064.267086 2.5188l11.740414-8.52977z" />
    </g>
  </svg>
)
