import React from 'react'

import { StyledButton } from './Button.styled'
import { ButtonProps } from './types'

/**
 * Basic Button UI
 */

export const Button = ({
  color = 'primary',
  size = 'medium',
  disabled = false,
  onClick,
  children,
  ...props
}: ButtonProps) => (
  <StyledButton
    type="button"
    className={`button--${color} button--${size}`}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {children}
  </StyledButton>
)
