import styled from 'styled-components'

import { Fallback, Root as AvatarRoot } from '@radix-ui/react-avatar'

import { ELECTRIC_BLUE, WHITE } from '../../../constants'
import { pxToRem } from '../../../utils/pixelToRem'

export const StyledAvatar = styled(AvatarRoot)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  width: ${(props) => pxToRem(32, props.theme.baseElemSize)};
  height: ${(props) => pxToRem(32, props.theme.baseElemSize)};
  border-radius: 100%;
  background-color: ${ELECTRIC_BLUE};
`

export const StyledFallback = styled(Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ELECTRIC_BLUE};
  color: ${WHITE};
  font-weight: 700;
  font-size: ${(props) => pxToRem(14, props.theme.baseElemSize)};
`
