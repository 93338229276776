import React from 'react'

import { UserAvatarProps } from './types'
import { StyledAvatar, StyledFallback } from './UserAvatar.styled'

export const UserAvatar = ({ name, userAccountTracking, handleTrackEvent }: UserAvatarProps) => (
  <StyledAvatar
    onMouseEnter={() => {
      if (handleTrackEvent && userAccountTracking) {
        handleTrackEvent('Hover', userAccountTracking)
      }
    }}
  >
    <StyledFallback>{name[0].toUpperCase()}</StyledFallback>
  </StyledAvatar>
)
