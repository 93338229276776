import { AA_BLUE, BLUE_2, COOL_GRAY_3, COOL_GRAY_5, DARK_BLUE, ELECTRIC_BLUE, MIST_BLUE, WHITE } from '../../constants'
import { defaultThemeBreakpoints } from '../defaultThemeBreakpints'

import { NavbarTheme } from './types'

export default {
  ...defaultThemeBreakpoints,
  baseElementSize: 16,
  name: 'light',
  nav: {
    background: WHITE,
    mobileBackground: WHITE,
    text: DARK_BLUE,
  },
  hamburgerButton: {
    background: DARK_BLUE,
  },
  subItems: {
    background: WHITE,
    title: DARK_BLUE,
    description: COOL_GRAY_3,
    icon: {
      fill: ELECTRIC_BLUE,
      bg: MIST_BLUE,
      bgHover: `${ELECTRIC_BLUE}29`,
    },
    separator: COOL_GRAY_5,
  },
  signIn: {
    text: AA_BLUE,
    textMobile: AA_BLUE,
    bg: 'transparent',
    bgHover: BLUE_2,
  },
  signOut: {
    text: AA_BLUE,
  },
} as NavbarTheme
