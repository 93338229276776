import React from 'react'

import { IconProps } from '../types'

export const AngleLeftLight = ({ className, width = 10, height = 16, fill }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 0 10 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.721057 7.46885L8.08212 0.220269C8.37581 -0.0734232 8.85072 -0.0734232 9.14441 0.220269L9.58807 0.663933C9.88177 0.957625 9.88177 1.43253 9.58807 1.72623L3.19557 8L9.58183 14.2738C9.87552 14.5675 9.87552 15.0424 9.58183 15.3361L9.13816 15.7797C8.84447 16.0734 8.36956 16.0734 8.07587 15.7797L0.714808 8.53115C0.427364 8.23745 0.427364 7.76255 0.721057 7.46885Z"
    />
  </svg>
)
