import React from 'react'

import { StyledContent, StyledItem } from './NavMenuItem.styled'
import { NavMenuItemProps } from './types'

export const NavMenuItem = ({ item, trigger, children, order }: NavMenuItemProps) => (
  <StyledItem key={item.title} value={item.title} order={order}>
    {trigger}
    {/* Use desktopwidth instead of desktopWidth to avoid warning */}
    <StyledContent desktopwidth={item.width}>{children}</StyledContent>
  </StyledItem>
)
