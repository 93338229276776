import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Item, Link } from '@radix-ui/react-navigation-menu'

import { pxToRem } from '../../../utils/pixelToRem'

export const StyledItem = styled(Item)`
  > div {
    display: flex;
  }
`

export const StyledUserMenuContainer = styled.div`
  margin: ${(props) => pxToRem(6, props.theme.baseElemSize)} 0 0 0;

  ${up('md')} {
    margin: 0 0 0 ${(props) => pxToRem(25, props.theme.baseElemSize)};
  }
`

export const StyledUserHeadlineContainer = styled.div`
  background-color: ${(props) => props.theme.subItems.background};
  padding: ${(props) => pxToRem('12 16', props.theme.baseElemSize)};
`

export const StyledSubItemsContainer = styled.div`
  background-color: ${(props) => props.theme.subItems.background};
  border-radius: ${(props) => pxToRem(12, props.theme.baseElemSize)};
  padding: ${(props) => pxToRem(12, props.theme.baseElemSize)} 0;
`

export const StyledSignInButton = styled(Link)`
  padding: ${(props) => pxToRem('12 16', props.theme.baseElemSize)};
  font-weight: 700;
  font-size: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  line-height: ${(props) => pxToRem(22, props.theme.baseElemSize)};
  border-radius: ${(props) => pxToRem(6, props.theme.baseElemSize)};
  text-decoration: none;
  color: ${(props) => props.theme.signIn.textMobile};
  width: 100%;
  display: block;

  ${up('md')} {
    padding: ${(props) => pxToRem('6 20', props.theme.baseElemSize)};
    line-height: ${(props) => pxToRem(24, props.theme.baseElemSize)};
    border: 2px solid ${(props) => props.theme.signIn.text};
    color: ${(props) => props.theme.signIn.text};
    text-align: center;

    &:hover {
      background-color: ${(props) => props.theme.signIn.bgHover};
      color: ${(props) => props.theme.signIn.text} !important;
    }
  }
`

export const StyledSignOutLink = styled(Link)`
  padding: ${(props) => pxToRem('12 16', props.theme.baseElemSize)};
  font-weight: 700;
  font-size: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  line-height: ${(props) => pxToRem(22, props.theme.baseElemSize)};
  border-radius: ${(props) => pxToRem(6, props.theme.baseElemSize)};
  text-decoration: none;
  color: ${(props) => props.theme.signIn.text};
  width: 100%;
  display: block;

  ${up('md')} {
    font-size: ${(props) => pxToRem(14, props.theme.baseElemSize)};
    line-height: ${(props) => pxToRem(20, props.theme.baseElemSize)};
    color: ${(props) => props.theme.signOut.text} !important;
  }

  &:focus {
    cursor: pointer;
  }
`

export const StyledSubItems = styled.div`
  width: 100%;

  ul {
    height: auto;
    width: 100%;

    li a {
      padding: ${(props) => pxToRem('16 6', props.theme.baseElemSize)};

      div {
        font-size: ${(props) => pxToRem(16, props.theme.baseElemSize)};
        line-height: ${(props) => pxToRem(22, props.theme.baseElemSize)};
      }
    }
  }
`
