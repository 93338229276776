import React, { SyntheticEvent, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsMobile, useIsTablet } from '../../hooks'
import navbarThemes from '../../themes/navbar'
import { GAEventType, GATrackActionType } from '../../types'
import { getRootElemFontSize } from '../../utils/pixelToRem'

import { StyledSignOutLink } from './NavUserMenu/NavUserMenu.styled'
import {
  StyledBackButtonAndTitle,
  StyledChildrenContainer,
  StyledCloseButton,
  StyledHamburgerButton,
  StyledHeaderContainer,
  StyledList,
  StyledMenuGroup,
  StyledRightButtonContainer,
  StyledRoot,
  StyledSeparator,
  StyledUserAvatarButton,
  StyledViewport,
  StyledViewportWrapper,
} from './NavMenu.styled'
import { NavMenuItem } from './NavMenuItem'
import { StyledLogo } from './NavMenuLogo'
import { NavTrigger } from './NavTrigger'
import { NavUserMenu } from './NavUserMenu'
import { SubItemsList } from './SubItemsList'
import { NavMenuProps } from './types'
import { UserAvatar } from './UserAvatar'
/**
 * NavMenu Component
 */
const rootElemFontSize = getRootElemFontSize()

export const NavMenu = ({
  items,
  theme,
  scrolledTheme,
  scrolled = false,
  maxWidth,
  baseElemSize,
  user,
  signInUrl,
  onSignOut,
  children,
  trackEvent,
  signInTracking,
  signOutTracking,
  hamburgerIconTracking,
  userAccountTracking,
  noWrapTrigger = false,
  narrowHorizontalPadding = false,
}: NavMenuProps) => {
  const [offset, setOffset] = useState<number | null>()
  const [arrowOffset, setArrowOffset] = useState<number | null>()
  const [list, setList] = useState<HTMLUListElement | null>()
  const [value, setValue] = useState<string>('')
  const [mobileOpened, setMobileOpened] = useState<boolean | null>(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const mobile = useIsMobile()
  const tablet = useIsTablet()

  const toggleMobileMenu = () => setMobileOpened(!mobileOpened)

  const onNodeUpdate = (trigger: HTMLButtonElement | null, itemValue?: string, itemWidth = '27.5rem') => {
    if (trigger && list && value === itemValue) {
      const windowWidthInRem = window.innerWidth / rootElemFontSize
      const triggerRect = trigger.getBoundingClientRect()
      const itemWidthInRem = parseFloat(itemWidth)

      const triggerOffsetInRem =
        Math.round((triggerRect.x + triggerRect.width * 0.5) / rootElemFontSize) - itemWidthInRem * 0.5

      let adjustedOffsetInRem = 0
      let offsetWidthInRem = 0
      if (!(mobile || tablet)) {
        offsetWidthInRem = ref.current ? Math.round(ref.current.offsetWidth / rootElemFontSize || 0) : itemWidthInRem

        // move to left if items list does not fit in window
        adjustedOffsetInRem =
          triggerOffsetInRem + offsetWidthInRem > windowWidthInRem
            ? windowWidthInRem - offsetWidthInRem - 1
            : triggerOffsetInRem
      }
      setArrowOffset(itemWidthInRem * 0.5 - (adjustedOffsetInRem - triggerOffsetInRem))
      setOffset(adjustedOffsetInRem)
    }
    return trigger
  }

  const getTheme = () => {
    const themeToApply = scrolled && scrolledTheme ? navbarThemes[scrolledTheme] : navbarThemes[theme]

    if (baseElemSize) return { ...themeToApply, baseElemSize }
    return themeToApply
  }

  const handleTrackEvent = (trackAction: GATrackActionType, { gaAction, gaLabel, gaCategory }: GAEventType): void => {
    if (trackEvent && gaAction === trackAction && gaAction && gaLabel && gaCategory) {
      const gaEvent: GAEventType = {
        gaCategory,
        gaLabel,
        gaAction,
      }
      trackEvent(gaEvent)
    }
  }

  const handleOnSignOut = (e: SyntheticEvent) => {
    if (signOutTracking) {
      handleTrackEvent('Tap', signOutTracking)
    }
    onSignOut(e)
  }

  const handleOnSignIn = () => {
    if (signInTracking) {
      handleTrackEvent('Tap', signInTracking)
    }
  }

  const handleOnHamburgerIconClick = () => {
    if (hamburgerIconTracking) {
      handleTrackEvent('Tap', hamburgerIconTracking)
    }
    toggleMobileMenu()
  }

  const upArrowIconWidthInRem = 10 / rootElemFontSize
  const triggerOffsetCssVar = {
    '--trigger-offset': `${(arrowOffset || 0) - upArrowIconWidthInRem}rem`,
  } as React.CSSProperties

  return (
    <ThemeProvider theme={getTheme()}>
      <StyledRoot onValueChange={setValue} value={value} orientation="horizontal" className="hl-core-ui">
        <StyledHeaderContainer maxwidth={maxWidth} narrowhorizontalpadding={narrowHorizontalPadding}>
          <StyledLogo showNameInMobileView={!children} />
          {children && <StyledChildrenContainer>{children}</StyledChildrenContainer>}
          <StyledMenuGroup state={mobileOpened ? 'opened' : 'closed'}>
            <StyledList ref={setList}>
              {items.map((item) => (
                <NavMenuItem
                  key={item.title}
                  item={item}
                  trigger={
                    <NavTrigger href={item.href} ref={(node) => onNodeUpdate(node, item.title, item.width)}>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                      <span
                        style={{ whiteSpace: noWrapTrigger ? 'nowrap' : 'normal' }}
                        onClick={() =>
                          handleTrackEvent('Tap', {
                            gaAction: item.gaAction,
                            gaLabel: item.gaLabel,
                            gaCategory: item.gaCategory,
                          } as GAEventType)
                        }
                        onMouseEnter={() =>
                          handleTrackEvent('Hover', {
                            gaAction: item.gaAction,
                            gaLabel: item.gaLabel,
                            gaCategory: item.gaCategory,
                          } as GAEventType)
                        }
                      >
                        {item.title}
                      </span>
                    </NavTrigger>
                  }
                >
                  {item.subitems?.length > 0 && (
                    <>
                      <StyledBackButtonAndTitle title={item.title} onClick={() => setValue('')} />
                      <SubItemsList subitems={item.subitems} handleTrackEvent={handleTrackEvent} />
                    </>
                  )}
                </NavMenuItem>
              ))}
              {(mobile || tablet) && <StyledSeparator />}
              <NavUserMenu
                user={user}
                signInUrl={signInUrl}
                noWrapSignIn={noWrapTrigger}
                onSignOut={onSignOut}
                handleTrackEvent={handleTrackEvent}
                ref={(node) => onNodeUpdate(node, user?.menu.title, user?.menu.width)}
                handleOnSignOut={handleOnSignOut}
                handleOnSignIn={handleOnSignIn}
                userAccountTracking={userAccountTracking}
              />
              {(mobile || tablet) && user && (
                <StyledSignOutLink onClick={handleOnSignOut} href="#">
                  Sign out
                </StyledSignOutLink>
              )}
            </StyledList>
            {mobileOpened && <StyledCloseButton onClick={toggleMobileMenu} />}
          </StyledMenuGroup>

          <StyledRightButtonContainer>
            {!mobileOpened &&
              (user ? (
                <StyledUserAvatarButton onClick={handleOnHamburgerIconClick}>
                  <UserAvatar name={user.name} />
                </StyledUserAvatarButton> // user logged
              ) : (
                <StyledHamburgerButton onClick={handleOnHamburgerIconClick} /> // Not logged
              ))}
          </StyledRightButtonContainer>
        </StyledHeaderContainer>
        <StyledViewportWrapper>
          <StyledViewport style={{ ...triggerOffsetCssVar, left: `${offset}rem` }} ref={ref} />
        </StyledViewportWrapper>
      </StyledRoot>
    </ThemeProvider>
  )
}
