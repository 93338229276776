import React, { ReactNode } from 'react'

import { breakpoints } from '../../../constants'

interface Props {
  children: ReactNode
}

export const DiscardPointerMoveForMobileWrapper = ({ children }: Props) => {
  const handlePointerEvent = (e: React.PointerEvent<HTMLDivElement>) => {
    if (window.innerWidth >= breakpoints.md) {
      return true
    }

    e.stopPropagation()
    return false
  }

  return <div onPointerMoveCapture={handlePointerEvent}>{children}</div>
}
