import { keyframes } from 'styled-components'

export const slideOutToLeft = keyframes`
  0% {
    left: 0;
  }
  
  100% {
    left: calc(-100vw - 1rem);
  }
`
