import React from 'react'

import { IconProps } from '../types'

export const TradeIn = ({ className, width = 21, height = 16, fill }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 0 21 16">
    <path d="m5.406 10.759-.02-.022-.582-.648-.1-.1-.03-.061a1.488 1.488 0 0 1 .223-1.96l6.226-5.496L9.215.775a1.292 1.292 0 0 0-.751-.29 1.29 1.29 0 0 0-.752.29L.52 7.124a.45.45 0 0 0-.033.635l.605.669a.52.52 0 0 0 .335.149.519.519 0 0 0 .3-.116l.447-.394v5.907a.9.9 0 0 0 .9.899h3.13v-3.675a1.556 1.556 0 0 1-.779-.418l-.019-.021Z" />
    <path d="m21.475 8.74-1.65-1.457V3.45a.45.45 0 0 0-.449-.45h-1.798a.45.45 0 0 0-.45.45v1.452l-2.845-2.508a1.12 1.12 0 0 0-1.502 0l-.865.767L5.59 8.74a.45.45 0 0 0-.033.635l.605.669a.448.448 0 0 0 .635.033l.447-.394v5.903a.9.9 0 0 0 .9.9h10.783a.898.898 0 0 0 .9-.9V9.683l.447.394c.085.069.19.11.3.116a.508.508 0 0 0 .334-.15l.605-.668a.523.523 0 0 0 .115-.301.513.513 0 0 0-.153-.335Zm-8.393-1.36.353-.357a.225.225 0 0 1 .319 0l3.08 3.079a.224.224 0 0 1 0 .317l-3.081 3.113a.225.225 0 0 1-.318 0l-.36-.357a.224.224 0 0 1 0-.317l2.098-2.118h-4.782a.224.224 0 0 1-.225-.225v-.504a.225.225 0 0 1 .225-.225h4.778l-2.087-2.088a.225.225 0 0 1 0-.318Z" />
  </svg>
)
