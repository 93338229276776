import React from 'react'

import { IconProps } from '../types'

export const HomeLightLogoLight = ({ className, width = 32, height = 33, fill }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 0 32 33">
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m32 16.5c0 8.8366-7.1634 16-16 16-8.83657 0-16-7.1634-16-16 0-8.83657 7.16343-16 16-16 8.8366 0 16 7.16343 16 16z"
        fill="#fff"
      />
      <path
        d="m1.38281 23.0053c2.49326 5.5934 8.09806 9.4946 14.61719 9.4946 6.5163 0 12.1188-3.8978 14.6139-9.4871l-14.6048-10.6322zm15.30789.0105h3.0894v-3.5536h-3.0894zm0 4.9269h3.0894v-3.5536h-3.0894zm-4.4624 0h3.0896v-3.5536h-3.0896zm0-4.9269h3.0896v-3.5536h-3.0896z"
        fill="#273653"
      />
      <path
        d="m31.6423 19.8662c.2327-1.0858.3577-2.2112.3577-3.3664 0-8.83661-7.1632-16.000044-16-16.000044-8.83657 0-16 7.163434-16 16.000044 0 1.1522.124571 2.2752.356114 3.3584l15.653986-11.37307z"
        fill="#46b6ff"
      />
    </g>
  </svg>
)
