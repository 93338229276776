import styled from 'styled-components'

import { BLUE_BG_HOVER, COOL_GRAY_2, COOL_GRAY_5, ELECTRIC_BLUE, ELECTRIC_BLUE_HOVER, WHITE } from '../../constants'

export const StyledButton = styled.button`
  all: unset;
  font-weight: 700;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  text-align: center;

  &.button {
    /* sizes */
    &--x-small {
      padding: 4px 12px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
    }
    &--small {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
    }
    &--medium {
      padding: 8px 20px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 6px;
    }
    &--large {
      padding: 12px 20px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 6px;
    }

    /* colors */
    &--primary {
      background-color: ${ELECTRIC_BLUE};
      color: ${WHITE};
      &:hover {
        background-color: ${ELECTRIC_BLUE_HOVER};
      }
      &:disabled {
        background-color: ${COOL_GRAY_5};
        color: ${COOL_GRAY_2};
        cursor: disabled;
      }
    }

    &--secondary {
      background-color: ${WHITE};
      border-color: ${ELECTRIC_BLUE};
      border-width: 1px;
      border-style: solid;
      color: ${ELECTRIC_BLUE};
      &:hover {
        background-color: ${BLUE_BG_HOVER};
      }
      &:disabled {
        background-color: ${WHITE};
        border-color: ${COOL_GRAY_5};
        border-width: 1px;
        border-style: solid;
        color: ${COOL_GRAY_5};
        cursor: disabled;
      }
    }
  }
`
