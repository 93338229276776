import React from 'react'

import { IconProps } from '../types'

export const CashOffer = ({ className, width = 20, height = 15, fill }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 0 20 15">
    <path
      fillRule="evenodd"
      d="M18.386.485H1.588a.933.933 0 0 0-.935.934v9.332a.933.933 0 0 0 .934.934h16.797a.933.933 0 0 0 .933-.934V1.42a.933.933 0 0 0-.931-.934ZM2.053 3.752V1.886H3.92a1.867 1.867 0 0 1-1.866 1.866Zm15.865 0a1.867 1.867 0 0 1-1.866-1.866h1.866v1.866Zm-1.866 6.533h1.866V8.418a1.867 1.867 0 0 0-1.866 1.867Zm-14 0V8.418a1.867 1.867 0 0 1 1.867 1.867H2.054Zm5.6-4.2c0 1.542 1.047 2.8 2.336 2.8 1.288 0 2.335-1.258 2.335-2.8 0-1.542-1.044-2.8-2.335-2.8s-2.336 1.258-2.336 2.8Z"
    />
    <path d="M18.96 13.085H1.012a.36.36 0 0 0-.36.36v.68c0 .2.161.36.36.36H18.96a.36.36 0 0 0 .359-.36v-.68a.36.36 0 0 0-.36-.36Z" />
  </svg>
)
