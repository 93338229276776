import React from 'react'
import { useTheme } from 'styled-components'

import {
  HomeLightLogoLight,
  HomeLightLogoTransparent,
  HomeLightTextLight,
  HomeLightTextTransparent,
} from '../../../icons'
import { NavbarTheme } from '../../../themes/navbar/types'

import { NavMenuLogoProps } from './types'

// prop showNameInMobileView var is used in the styles for the component
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NavMenuLogo = ({ className, showNameInMobileView = true }: NavMenuLogoProps) => {
  const theme = useTheme() as NavbarTheme
  return (
    <div className={className}>
      <a href="/">
        {theme.name === 'light' ? (
          <>
            <HomeLightLogoLight width={32} height={32} />
            <HomeLightTextLight width={122} height={24} />
          </>
        ) : (
          <>
            <HomeLightLogoTransparent width={32} height={32} />
            <HomeLightTextTransparent width={122} height={24} />
          </>
        )}
      </a>
    </div>
  )
}
