import React, { forwardRef } from 'react'

import { GAEventType } from '../../../types'
import { IconResolver } from '../IconResolver'
import {
  StyledLink,
  StyledLinkDescription,
  StyledLinkIcon,
  StyledLinkText,
  StyledLinkTitle,
  StyledSeparator,
  StyledSubItemsList,
  StyledUpArrow,
} from '../NavMenu.styled'
import { SubMenuItemType } from '../types'

import { NavLinkProps, SubItemsListProps, SubItemsProps } from './types'

const ListItem = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, title, iconClass, handleTrackEvent, gaCategory, gaLabel, gaAction, ...props }, forwardedRef) => {
    return (
      <li>
        <StyledLink
          {...props}
          ref={forwardedRef}
          data-icon={iconClass}
          onClick={() =>
            handleTrackEvent &&
            handleTrackEvent('Tap', {
              gaAction,
              gaLabel,
              gaCategory,
            } as GAEventType)
          }
          onMouseEnter={() =>
            handleTrackEvent &&
            handleTrackEvent('Hover', {
              gaAction,
              gaLabel,
              gaCategory,
            } as GAEventType)
          }
        >
          {iconClass && (
            <StyledLinkIcon data-icon={iconClass}>
              <IconResolver icon={iconClass} />
            </StyledLinkIcon>
          )}
          <StyledLinkText>
            <StyledLinkTitle>{title}</StyledLinkTitle>
            {children && <StyledLinkDescription>{children}</StyledLinkDescription>}
          </StyledLinkText>
        </StyledLink>
      </li>
    )
  }
)

const SubItems = ({ subitems, handleTrackEvent }: SubItemsProps) => (
  <>
    {subitems.map((subitem) => (
      <ListItem
        key={subitem.title}
        href={subitem.href}
        title={subitem.title || ''}
        iconClass={subitem.iconClass}
        gaCategory={subitem?.gaCategory}
        gaLabel={subitem?.gaLabel}
        gaAction={subitem?.gaAction}
        handleTrackEvent={handleTrackEvent}
      >
        {subitem.details}
      </ListItem>
    ))}
  </>
)

export const SubItemsList = ({ subitems, handleTrackEvent }: SubItemsListProps) => {
  if (!Array.isArray(subitems[0]))
    return (
      <>
        <StyledUpArrow />
        <StyledSubItemsList>
          <SubItems subitems={subitems as SubMenuItemType[]} handleTrackEvent={handleTrackEvent} />
        </StyledSubItemsList>
      </>
    )

  return (
    <>
      <StyledUpArrow />
      <StyledSubItemsList>
        {subitems.map((itemArray, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`subitems_array_${idx}`}>
            <SubItems subitems={itemArray as SubMenuItemType[]} handleTrackEvent={handleTrackEvent} />
            {idx < subitems.length - 1 && <StyledSeparator />}
          </div>
        ))}
      </StyledSubItemsList>
    </>
  )
}
