import { keyframes } from 'styled-components'

export const slideInFromLeft = keyframes`
  0% {
    left: calc(-100vw - 1rem);
  }

  100% {
    left: 0;
  }
`
