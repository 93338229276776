import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import { pxToRem } from '../../../utils/pixelToRem'

import { NavMenuLogo } from './NavMenuLogo'

export const clearFix = css`
  :after {
    clear: both;
    content: ' ';
    display: table;
  }
`

export const StyledLogo = styled(NavMenuLogo)`
  flex: 0.1;
  padding: ${(props) => pxToRem('14 16', props.theme.baseElemSize)};

  > a {
    display: flex;
    cursor: pointer;
    text-decoration: none;

    > svg {
      fill: ${(props) => props.theme.nav.text} !important;
    }

    > svg:nth-child(2) {
      margin-left: ${(props) => pxToRem(6, props.theme.baseElemSize)};
      margin-top: ${(props) => pxToRem(8, props.theme.baseElemSize)};
      display: ${(props) => (props.showNameInMobileView ? 'block' : 'none')};
      width: ${(props) => pxToRem(92, props.theme.baseElemSize)};
      height: ${(props) => pxToRem(18, props.theme.baseElemSize)};
    }

    ${up('md')} {
      padding: ${(props) => pxToRem('4 16', props.theme.baseElemSize)};
      width: ${(props) => pxToRem(160, props.theme.baseElemSize)};

      > svg:nth-child(2) {
        display: block;
        // margin-top: ${(props) => pxToRem(6, props.theme.baseElemSize)};
        // width: ${(props) => pxToRem(120, props.theme.baseElemSize)};
        // height: ${(props) => pxToRem(24, props.theme.baseElemSize)};
      }
    }
  }

  ${up('md')} {
    flex: 0;
    padding: 0;
  }
`
