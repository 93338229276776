import styled from 'styled-components'

import { COOL_GRAY_3, DARK_BLUE } from '../../../constants'
import { pxToRem } from '../../../utils/pixelToRem'

export const StyledUserHeadlineContainer = styled.div`
  display: flex;
  gap: ${(props) => pxToRem(10, props.theme.baseElemSize)};
  align-items: center;
`

export const StyledUserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => pxToRem(2, props.theme.baseElemSize)};
`

export const StyledUserName = styled.span`
  color: ${DARK_BLUE};
  font-weight: 700;
  font-size: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  line-height: ${(props) => pxToRem(24, props.theme.baseElemSize)};
`

export const StyledUserEmail = styled.span`
  color: ${COOL_GRAY_3};
  font-weight: 400;
  font-size: ${(props) => pxToRem(14, props.theme.baseElemSize)};
  line-height: ${(props) => pxToRem(20, props.theme.baseElemSize)};
`
