import React, { forwardRef } from 'react'

import { useIsMobile, useIsTablet } from '../../../hooks'
import { StyledSeparator, StyledUpArrow } from '../NavMenu.styled'
import { NavMenuItem } from '../NavMenuItem'
import { NavTrigger } from '../NavTrigger'
import { SubItemsList } from '../SubItemsList'
import { UserAvatar } from '../UserAvatar'
import { UserHeadline } from '../UserHeadline'

import {
  StyledItem,
  StyledSignInButton,
  StyledSignOutLink,
  StyledSubItems,
  StyledSubItemsContainer,
  StyledUserHeadlineContainer,
  StyledUserMenuContainer,
} from './NavUserMenu.styled'
import { DesktopMenuProps, DesktopTriggerProps, MobileMenuProps, NavUserMenuProps } from './types'

const DesktopTrigger = forwardRef<HTMLButtonElement, DesktopTriggerProps>(
  ({ user, userAccountTracking, handleTrackEvent }, forwardedRef) => (
    <NavTrigger ref={forwardedRef}>
      <UserAvatar name={user.name} userAccountTracking={userAccountTracking} handleTrackEvent={handleTrackEvent} />
    </NavTrigger>
  )
)

const DesktopMenu = ({ user, handleTrackEvent, handleOnSignOut }: DesktopMenuProps) => (
  <>
    <StyledUpArrow />
    <StyledSubItemsContainer>
      <StyledUserHeadlineContainer>
        <UserHeadline name={user.name} email={user.email} />
      </StyledUserHeadlineContainer>

      <StyledSeparator />
      <SubItemsList subitems={user.menu.subitems} handleTrackEvent={handleTrackEvent} />
      <StyledSeparator />

      <StyledSignOutLink onClick={handleOnSignOut} href="#">
        Sign out
      </StyledSignOutLink>
    </StyledSubItemsContainer>
  </>
)

const MobileMenu = ({ user, handleTrackEvent }: MobileMenuProps) => (
  <>
    <StyledUserHeadlineContainer>
      <UserHeadline name={user.name} email={user.email} />
    </StyledUserHeadlineContainer>

    <StyledSeparator />

    <StyledSubItems>
      <SubItemsList subitems={user.menu.subitems} handleTrackEvent={handleTrackEvent} />
    </StyledSubItems>

    <StyledSeparator />
  </>
)

export const NavUserMenu = forwardRef<HTMLButtonElement, NavUserMenuProps>(
  (
    {
      user,
      signInUrl,
      noWrapSignIn = false,
      onSignOut,
      handleTrackEvent,
      handleOnSignOut,
      handleOnSignIn,
      userAccountTracking,
    },
    forwardedRef
  ) => {
    const mobile = useIsMobile()
    const tablet = useIsTablet()

    if (!user) {
      return (
        <StyledItem key="sign_in" value="sign_in">
          <StyledUserMenuContainer>
            <StyledSignInButton href={signInUrl} onClick={() => handleOnSignIn()}>
              <span style={{ whiteSpace: noWrapSignIn ? 'nowrap' : 'normal' }}>Sign in</span>
            </StyledSignInButton>
          </StyledUserMenuContainer>
        </StyledItem>
      )
    }

    return (
      <NavMenuItem
        key={user.menu.title}
        item={user.menu}
        trigger={
          mobile || tablet ? (
            <MobileMenu
              user={user}
              onSignOut={onSignOut}
              handleTrackEvent={handleTrackEvent}
              handleOnSignOut={handleOnSignOut}
            />
          ) : (
            <DesktopTrigger
              ref={forwardedRef}
              user={user}
              userAccountTracking={userAccountTracking}
              handleTrackEvent={handleTrackEvent}
            />
          )
        }
        order={mobile || tablet ? -1 : undefined}
      >
        <DesktopMenu
          user={user}
          onSignOut={onSignOut}
          handleTrackEvent={handleTrackEvent}
          handleOnSignOut={handleOnSignOut}
        />
      </NavMenuItem>
    )
  }
)
