import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import { Link, List, Root, Viewport } from '@radix-ui/react-navigation-menu'
import { Root as SeparatorRoot } from '@radix-ui/react-separator'

import { fadeIn, fadeOut, slideInFromLeft, slideOutToLeft } from '../../animations'
import { UpArrow } from '../../icons/UpArrow'
import { pxToRem } from '../../utils/pixelToRem'

import {
  NavMenuBackButtonAndTitle,
  NavMenuChildrenContainer,
  NavMenuCloseButton,
  NavMenuGroup,
  NavMenuHamburgerButton,
} from './NavCustomPrimitives'

const SUBMENU_ITEMS_ANIMATION_TIME = '200ms'
const SM_ICON_SIZE = '40px'
const LG_ICON_SIZE = '60px'

export const clearFix = css`
  :after {
    clear: both;
    content: ' ';
    display: table;
  }
`

export const StyledRoot = styled(Root)`
  display: flex;
  position: fixed;
  width: 100vw;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 100;

  ${up('md')} {
    all: unset;
    display: flex;
    position: relative;
  }
`

export const StyledHeaderContainer = styled.div<{
  maxwidth?: string
  narrowhorizontalpadding?: boolean
}>`
  ${clearFix};
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.nav.background};
  transition: background-color ${SUBMENU_ITEMS_ANIMATION_TIME} linear;
  align-items: flex-start;

  ${up('md')} {
    padding: ${(props) =>
      props.narrowhorizontalpadding
        ? pxToRem('16 32', props.theme.baseElemSize)
        : pxToRem('16 80', props.theme.baseElemSize)};
    align-items: center;
    margin: auto;
    ${(props) => (props.maxwidth ? `max-width: ${props.maxwidth};` : '')}
  }
`

export const StyledChildrenContainer = styled(NavMenuChildrenContainer)`
  width: 100%;
  > * {
    width: 100%;
  }

  ${up('md')} {
    flex: 0.5;
  }
`

export const StyledCloseButton = styled(NavMenuCloseButton)`
  all: unset;
  position: absolute;
  z-index: 10000;
  margin: ${(props) => pxToRem('22 16', props.theme.baseElemSize)};
  right: 0;
  top: 0;
  display: inline-block;
  transform: scale(0.9);
  color: black;
  cursor: pointer;
  > span {
    background: black;
    width: ${(props) => pxToRem(18, props.theme.baseElemSize)};
    height: ${(props) => pxToRem(2, props.theme.baseElemSize)};
    margin: ${(props) => pxToRem(5, props.theme.baseElemSize)} auto;
    display: block;
    :nth-child(1) {
      transform: translateY(${(props) => pxToRem(3.5, props.theme.baseElemSize)}) rotate(-45deg);
    }
    :nth-child(2) {
      transform: translateY(${(props) => pxToRem(-3.5, props.theme.baseElemSize)}) rotate(45deg);
    }
  }
  ${up('md')} {
    display: none;
  }
`

export const StyledMenuGroup = styled(NavMenuGroup)`
  background-color: ${(props) => props.theme.nav.mobileBackground};
  position: absolute;
  padding-top: ${(props) => pxToRem(64, props.theme.baseElemSize)};
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  left: calc(-100% - 10px);
  transition: left ${SUBMENU_ITEMS_ANIMATION_TIME} ease;
  &[data-state='opened'] {
    left: 0;
  }
  z-index: 1000;

  ${up('md')} {
    all: unset;
    display: flex;
    flex: 1;
    align-items: center;
    > div {
      margin-left: auto;
    }
  }
`

export const StyledList = styled(List)`
  all: unset;
  display: flex;
  background-color: ${(props) => props.theme.nav.mobileBackground};
  position: absolute;
  width: 100%;
  left: 0;
  flex-direction: column;

  ${up('md')} {
    all: unset;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    border-radius: ${(props) => pxToRem(6, props.theme.baseElemSize)};
    list-style: none;
    background-color: transparent;
    align-items: center;
  }
`

export const StyledUpArrow = styled(UpArrow)`
  display: none;

  ${up('md')} {
    display: block;
    width: ${(props) => pxToRem(20, props.theme.baseElemSize)};
    margin-bottom: ${(props) => pxToRem(-1, props.theme.baseElemSize)};
    fill: ${(props) => props.theme.subItems.background};
  }
`

export const StyledSubItemsList = styled.ul`
  padding: ${(props) => pxToRem(10, props.theme.baseElemSize)};
  margin: 0;
  list-style: none;
  border-radius: ${(props) => pxToRem(12, props.theme.baseElemSize)};
  background-color: ${(props) => props.theme.subItems.background};

  ${up('md')} {
    padding: ${(props) => pxToRem(12, props.theme.baseElemSize)} 0;
  }
`

export const StyledLinkText = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledBackButtonAndTitle = styled(NavMenuBackButtonAndTitle)`
  color: black;
  font-size: ${(props) => pxToRem(24, props.theme.baseElemSize)};
  font-weight: 700;
  line-height: 1;
  padding: ${(props) => pxToRem(16, props.theme.baseElemSize)};
  background-color: ${(props) => props.theme.subItems.background};

  > button {
    all: unset;
    margin: ${(props) => pxToRem('10 10 10 -10', props.theme.baseElemSize)};

    cursor: pointer;
    > svg {
      fill: black;
      width: ${(props) => pxToRem(12, props.theme.baseElemSize)};
      margin: ${(props) => pxToRem('8 10', props.theme.baseElemSize)};
    }
  }

  ${up('md')} {
    display: none;
  }
`

export const StyledLink = styled(Link)`
  outline: none;
  user-select: none;
  font-weight: 500;
  line-height: 1;
  border-radius: ${(props) => pxToRem(4, props.theme.baseElemSize)};
  font-size: ${(props) => pxToRem(14, props.theme.baseElemSize)};
  color: rgb(87, 70, 175);
  text-decoration: none;
  padding: ${(props) => pxToRem('12 6', props.theme.baseElemSize)};
  display: flex;

  ${up('md')} {
    padding: ${(props) => pxToRem('16 24', props.theme.baseElemSize)};
  }

  &:focus {
    position: relative;
    box-shadow: rgb(196 184 243) 0 0 0 ${(props) => pxToRem(2, props.theme.baseElemSize)};
  }

  &:not([data-icon]) {
    ${up('md')} {
      padding: ${(props) => pxToRem('10 16', props.theme.baseElemSize)};
    }
  }
`

export const StyledLinkIcon = styled.div<{
  size?: 'small' | 'large'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 ${(props) => pxToRem(16, props.theme.baseElemSize)} 0 0;
  width: ${(props) => (props.size === 'large' ? LG_ICON_SIZE : SM_ICON_SIZE)};
  height: ${(props) => (props.size === 'large' ? LG_ICON_SIZE : SM_ICON_SIZE)};
  border-radius: ${(props) => pxToRem(6, props.theme.baseElemSize)};
  background: ${(props) => props.theme.subItems.icon.bg};

  ${StyledLink}:hover & {
    background-color: ${(props) => props.theme.subItems.icon.bgHover};
  }

  svg {
    fill: ${(props) => props.theme.subItems.icon.fill};
    max-width: ${(props) => pxToRem(18, props.theme.baseElemSize)};
    max-height: ${(props) => pxToRem(18, props.theme.baseElemSize)};
    width: 100%;
    height: 100%;
  }

  &[data-icon='tradeIn'] {
    svg {
      max-width: ${(props) => pxToRem(22, props.theme.baseElemSize)};
    }
  }

  &[data-icon='percentage'] {
    svg {
      max-width: ${(props) => pxToRem(14, props.theme.baseElemSize)};
    }
  }
`

export const StyledLinkTitle = styled.div`
  line-height: 1.57;
  margin-bottom: ${(props) => pxToRem(2, props.theme.baseElemSize)};
  font-weight: 700;
  font-size: ${(props) => pxToRem(14, props.theme.baseElemSize)};
  color: ${(props) => props.theme.subItems.title};
`

export const StyledLinkDescription = styled.div`
  all: unset;
  color: ${(props) => props.theme.subItems.description};
  line-height: 1.33;
  font-size: ${(props) => pxToRem(12, props.theme.baseElemSize)};
  font-weight: 600;
`

export const StyledViewportWrapper = styled.div`
  z-index: 1000;
  top: 0;

  ${up('md')} {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    display: flex;

    & > div > div > svg {
      margin-left: var(--trigger-offset) !important;
    }
  }
`

export const StyledViewport = styled(Viewport)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${(props) => props.theme.subItems.background};

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideInFromLeft} ${SUBMENU_ITEMS_ANIMATION_TIME} ease;
    }
    &[data-state='closed'] {
      animation: ${slideOutToLeft} ${SUBMENU_ITEMS_ANIMATION_TIME} ease;
    }
  }

  ${up('md')} {
    left: unset;
    top: unset;
    margin: unset;
    position: relative;
    display: inline-block;
    width: unset;
    min-height: unset;
    margin-right: ${(props) => pxToRem(20, props.theme.baseElemSize)};
    border-bottom-left-radius: ${(props) => pxToRem(12, props.theme.baseElemSize)};
    border-bottom-right-radius: ${(props) => pxToRem(12, props.theme.baseElemSize)};

    filter: drop-shadow(0 14px 48px rgba(108, 129, 171, 0.2));

    background-color: unset;

    &[data-state='open'] {
      animation-name: ${fadeIn};
    }
    &[data-state='closed'] {
      animation-name: ${fadeOut};
    }
  }
`

export const StyledSeparator = styled(SeparatorRoot)`
  background-color: ${(props) => props.theme.subItems.separator};
  margin: ${(props) => pxToRem('10 16', props.theme.baseElemSize)};
  &[data-orientation='horizontal'] {
    height: 1px;
  }
  &[data-orientation='vertical'] {
    height: 100%;
    width: 1px;
  }
`

export const StyledRightButtonContainer = styled.div`
  flex: 1;
  text-align: right;
  vertical-align: middle;
  display: inline-block;
  padding: 14px 14px;

  ${up('md')} {
    display: none;
  }
`

export const StyledUserAvatarButton = styled(Link)`
  cursor: pointer;

  ${up('md')} {
    display: none;
  }
`

export const StyledHamburgerButton = styled(NavMenuHamburgerButton)`
  all: unset;
  z-index: 10000;
  cursor: pointer;
  > span {
    background: ${(props) => props.theme.hamburgerButton.background};
    width: ${(props) => pxToRem(20, props.theme.baseElemSize)};
    height: ${(props) => pxToRem(2, props.theme.baseElemSize)};
    margin: ${(props) => pxToRem(5, props.theme.baseElemSize)} auto;
    display: block;
  }

  ${up('md')} {
    display: none;
  }
`
